$pgenMain: #d8aaed;
%centeredObj {
  display: flex;
  align-items: center;
  justify-content: center;
}
%boxshadow {
   -webkit-box-shadow: 3px 3px 0 0 rgba(0,0,0,0.8);
    box-shadow: 3px 3px 0 0 rgba(0,0,0,0.8) ;
}
#personalityGenerator, #scentGenerator  {
    text-shadow: 1px 1px 0 rgba(128,128,128,0.8) ;
  margin: 0 auto;
  @extend %centeredObj;  
  div {
    float:left;
    margin: 20px;
    padding: 20px;
    @extend %boxshadow;
    p {
      background-color: white;
      padding: 5px;
      border: dotted $pgenMain 3px;
      font-weight: bold;
      
    }
    input[name="character"] {
      @extend %centeredObj;
      margin: 0 auto;
    }
    input[name="gender"] + label {
      text-align: center;
      
    }
  }
 
}
button {
    @extend %centeredObj;  
    font-weight: bold;
    background-color: $pgenMain;
    cursor: pointer;
    padding: 9px 10px;
    border: 1px solid;
    margin: 0 auto;
    text-align: center;
    @extend %boxshadow;
      &:hover {
        background-color: darken($pgenMain, 20%);
      }
      &:active {
         background-color: lighten($pgenMain, 10%);
         -webkit-box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.8) ;
         box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.8) ;
         }
    }


input[name="character"] {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 1em 1em;
  -o-text-overflow: clip;
  text-overflow: clip;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) inset;
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) inset;
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  &:hover {
  -webkit-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1);
  }
  &:focus {
  -webkit-box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.2) inset;
  box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.2) inset;
  -webkit-transition: all 50ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 50ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 50ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 50ms cubic-bezier(0.42, 0, 0.58, 1);
  }
}

@media screen and (max-width:540px) {
#personalityGenerator, #scentGenerator, #personalityGenerator div input[name="character"], #scentGenerator div input[name="character"], button {
    display: block;
}}